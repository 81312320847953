import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedagogical-proposal',
  templateUrl: './pedagogical-proposal.component.html',
  styleUrls: ['./pedagogical-proposal.component.css']
})
export class PedagogicalProposalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onWindowScroll($event: UIEvent) {
    
  }
}
