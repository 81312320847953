import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comunity',
  templateUrl: './comunity.component.html',
  styleUrls: ['./comunity.component.css']
})
export class ComunityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onWindowScroll($event: UIEvent) {

  }
}
