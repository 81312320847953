import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component'
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { PedagogicalProposalComponent } from './components/pedagogical-proposal/pedagogical-proposal.component';
import { ComunityComponent } from './components/comunity/comunity.component';
import { DonationsComponent } from './components/donations/donations.component';
import { from } from 'rxjs';




const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: '',  redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'nosotros', component: AboutComponent },
  { path: 'servicios', component: ServicesComponent },
  { path: 'pedagogica', component: PedagogicalProposalComponent},
  { path: 'comunidad', component: ComunityComponent},
  { path: 'contacto', component: ContactComponent},
  { path: 'donaciones', component: DonationsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
