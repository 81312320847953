import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  constructor() { }
  showNavigationIndicators = true;
  showNavigationArrows = false;



  images = [
    './assets/img/img-01.jpg',
    './assets/img/img-02.jpg',
    './assets/img/img-03.jpg',
    './assets/img/img-04.jpg',
    './assets/img/img-05.jpg',
  ];
  ngOnInit() {
  }




}
