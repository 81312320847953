import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ContactusService } from 'src/app/service/contactus.service';
// import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private service: ContactusService) { }

  public map: any = { lat: -39.86433, lng: -73.39249, label: 'Escuela Viva RelmuLafken'};
  zoom = 15;

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    const value = form.value;
    const name = value.name;
    const email = 'relmuescuelaviva@gmail.com';
    const message = value.content;
    const subject = value.subject;
    const emailContact = value.email;

   const formRequest = { name, email, subject, message, emailContact};
    // this.af.list('/messages').push(formRequest);
    form.reset();
      console.log(formRequest);

    this.service.sendEmail(formRequest)
        .subscribe(res => {
              form.reset();
              // this.emailSent = true;
                console.log('response cloud function' + res);
            },
            error => {
              console.log('TCL: ContactusComponent -> onSubmit -> error', error);
            }
        );

}

  onWindowScroll($event: Event) {

  }
}
